import {Header} from "./Header";
import React from "react";

export const MMRTable = () => {
    return (
        <>
            <Header/>
            <div className={`mmr-table`}>
                <div className={'column'}>
                    <table>
                        <tbody>
                        <tr>
                            <th colSpan={1} rowSpan={1}>
                                <p>
                                    <strong>Medal</strong>
                                </p>
                            </th>
                            <th colSpan={1} rowSpan={1}>
                                <p>MMR</p>
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-herald-1.png"
                                        alt="Dota 2 seasonal rank medal Herald 1"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Herald 1
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>&nbsp;1-154 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-herald-2.png"
                                        alt="Dota 2 seasonal rank medal Herald 2"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Herald 2
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>154-308 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-herald-3.png"
                                        alt="Dota 2 seasonal rank medal Herald 3"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Herald 3
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>308-462 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-herald-4.png"
                                        alt="Dota 2 seasonal rank medal Herald 4"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Herald 4
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>462-616 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-herald-5.png"
                                        alt="Dota 2 seasonal rank medal Herald 5"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Herald 5
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>616-769 MMR</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className={'column'}>
                    <table>
                        <tbody>
                        <tr>
                            <th colSpan={1} rowSpan={1}>
                                <p>
                                    <strong>Medal</strong>
                                </p>
                            </th>
                            <th colSpan={1} rowSpan={1}>
                                <p>MMR</p>
                            </th>
                        </tr>

                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-guardian-1.png"
                                        alt="Dota 2 seasonal rank medal Guardian 1"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Guardian&nbsp;1
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>770-924 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-guardian-2.png"
                                        alt="Dota 2 seasonal rank medal Guardian 2"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Guardian&nbsp;2
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>924-1078 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-guardian-3.png"
                                        alt="Dota 2 seasonal rank medal Guardian 3"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Guardian&nbsp;3
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>1078-1232 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-guardian-4.png"
                                        alt="Dota 2 seasonal rank medal Guardian 4"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Guardian&nbsp;4
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>1232-1386 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-guardian-5.png"
                                        alt="Dota 2 seasonal rank medal Guardian 5"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Guardian&nbsp;5
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>1386-1540 MMR</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={'mmr-table'}>
                <div className={'column'}>
                    <table>
                        <tbody>
                        <tr>
                            <th colSpan={1} rowSpan={1}>
                                <p>
                                    <strong>Medal</strong>
                                </p>
                            </th>
                            <th colSpan={1} rowSpan={1}>
                                <p>MMR</p>
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-crusader-1.png"
                                        alt="Dota 2 seasonal rank medal Crusader 1"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;Crusader&nbsp;1
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>1540-1694 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-crusader-2.png"
                                        alt="Dota 2 seasonal rank medal Crusader 2"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;Crusader&nbsp;2
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>1694-1848 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-crusader-3.png"
                                        alt="Dota 2 seasonal rank medal Crusader 3"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;Crusader&nbsp;3
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>1848-2002 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-crusader-4.png"
                                        alt="Dota 2 seasonal rank medal Crusader 4"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;Crusader&nbsp;4
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>2002-2156 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-crusader-5.png"
                                        alt="Dota 2 seasonal rank medal Crusader 5"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;Crusader&nbsp;5
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>2156-2310 MMR</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className={'column'}>
                    <table>
                        <tbody>
                        <tr>
                            <th colSpan={1} rowSpan={1}>
                                <p>
                                    <strong>Medal</strong>
                                </p>
                            </th>
                            <th colSpan={1} rowSpan={1}>
                                <p>MMR</p>
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-archon-1.png"
                                        alt="Dota 2 seasonal rank medal Archon 1"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;Archon&nbsp;1
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>2310-2464 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-archon-2.png"
                                        alt="Dota 2 seasonal rank medal Archon 2"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;Archon&nbsp;2
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>2464-2618 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-archon-3.png"
                                        alt="Dota 2 seasonal rank medal Archon 3"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;Archon&nbsp;3
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>2618-2772 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-archon-4.png"
                                        alt="Dota 2 seasonal rank medal Archon 4"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;Archon&nbsp;4
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>2772-2926 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-archon-5.png"
                                        alt="Dota 2 seasonal rank medal Archon 5"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;Archon&nbsp;5
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>2926-3080 MMR</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={`mmr-table`}>
                <div className={'column'}>
                    <table>
                        <tbody>
                        <tr>
                            <th colSpan={1} rowSpan={1}>
                                <p>
                                    <strong>Medal</strong>
                                </p>
                            </th>
                            <th colSpan={1} rowSpan={1}>
                                <p>MMR</p>
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-legend-1.png"
                                        alt="Dota 2 seasonal rank medal Legend 1"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;Legend&nbsp;1
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>3080-3234 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-legend-2.png"
                                        alt="Dota 2 seasonal rank medal Legend 2"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;Legend&nbsp;2
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>3234-3388 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-legend-3.png"
                                        alt="Dota 2 seasonal rank medal Legend 3"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;Legend&nbsp;3
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>3388-3542 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-legend-4.png"
                                        alt="Dota 2 seasonal rank medal Legend 4"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;Legend&nbsp;4
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>3542-3696 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-legend-5.png"
                                        alt="Dota 2 seasonal rank medal Legend 5"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;Legend&nbsp;5
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>3696-3850 MMR</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className={'column'}>
                    <table>
                        <tbody>
                        <tr>
                            <th colSpan={1} rowSpan={1}>
                                <p>
                                    <strong>Medal</strong>
                                </p>
                            </th>
                            <th colSpan={1} rowSpan={1}>
                                <p>MMR</p>
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-ancient-1.png"
                                        alt="Dota 2 seasonal rank medal Ancient 1"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;Ancient 1
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>3850-4004 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-ancient-2.png"
                                        alt="Dota 2 seasonal rank medal Ancient 2"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Ancient&nbsp;2
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>4004-4158 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-ancient-3.png"
                                        alt="Dota 2 seasonal rank medal Ancient 3"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Ancient&nbsp;3
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>4158-4312 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-ancient-4.png"
                                        alt="Dota 2 seasonal rank medal Ancient 4"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Ancient&nbsp;4
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>4312-4466 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-ancient-5.png"
                                        alt="Dota 2 seasonal rank medal Ancient 5"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Ancient&nbsp;5
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>4466-4620 MMR</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={`mmr-table`}>
                <div className={'column'}>
                    <table>
                        <tbody>
                        <tr>
                            <th colSpan={1} rowSpan={1}>
                                <p>
                                    <strong>Medal</strong>
                                </p>
                            </th>
                            <th colSpan={1} rowSpan={1}>
                                <p>MMR</p>
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-ancient-1.png"
                                        alt="Dota 2 seasonal rank medal Ancient 1"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;Ancient 1
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>3850-4004 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-ancient-2.png"
                                        alt="Dota 2 seasonal rank medal Ancient 2"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Ancient&nbsp;2
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>4004-4158 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-ancient-3.png"
                                        alt="Dota 2 seasonal rank medal Ancient 3"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Ancient&nbsp;3
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>4158-4312 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-ancient-4.png"
                                        alt="Dota 2 seasonal rank medal Ancient 4"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Ancient&nbsp;4
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>4312-4466 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-ancient-5.png"
                                        alt="Dota 2 seasonal rank medal Ancient 5"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Ancient&nbsp;5
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>4466-4620 MMR</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className={'column'}>
                    <table>
                        <tbody>
                        <tr>
                            <th colSpan={1} rowSpan={1}>
                                <p>
                                    <strong>Medal</strong>
                                </p>
                            </th>
                            <th colSpan={1} rowSpan={1}>
                                <p>MMR</p>
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-divine-1.png"
                                        alt="Dota 2 seasonal rank medal Divine 1"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    &nbsp;&nbsp;Divine&nbsp;1
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>4620-4820 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-divine-2.png"
                                        alt="Dota 2 seasonal rank medal Divine 2"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Divine&nbsp;2
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>4820-5020 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-divine-3.png"
                                        alt="Dota 2 seasonal rank medal Divine 3"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Divine&nbsp;3
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>5020-5220 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-divine-4.png"
                                        alt="Dota 2 seasonal rank medal Divine 4"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Divine&nbsp;4
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>5220-5420 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-divine-5.png"
                                        alt="Dota 2 seasonal rank medal Divine 5"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Divine&nbsp;5
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>5420-5620 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-immortal.png"
                                        alt="Dota 2 seasonal rank medal Immortal "
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Immortal&nbsp;top
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>5620-8300 MMR</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-immortal.png"
                                        alt="Dota 2 seasonal rank medal Immortal top 1000"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Immortal&nbsp;top 1000
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>Regional Leaderboard</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-immortal-top-100.png"
                                        alt="Dota 2 seasonal rank medal Immortal top 100"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Immortal&nbsp;top 100
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>Regional Leaderboard</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    <img
                                        src="https://hawk.live/images/dota-2-seasonal-ranking-medals/seasonal-rank-immortal-top-10.png"
                                        alt="Dota 2 seasonal rank medal Immortal top 10"
                                        height={64}
                                        loading="lazy"
                                    />{" "}
                                    Immortal&nbsp;top 10
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>Regional Leaderboard</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}